import { Provider, ErrorBoundary } from '@rollbar/react';
import settings from 'config/settings';

const rollbarConfig = {
  accessToken: settings.rollbarToken,
  environment: process.env.REACT_APP_ENV,
  enabled: !!process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  verbose: true,
  autoInstrument: {
    log: false,
  }
};

const RollbarProvider = (props) => {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Provider>
  );
}

export default RollbarProvider;