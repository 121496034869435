import React, { Suspense, Fragment, lazy } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ClimbingBoxLoader } from 'react-spinners';
import { FormattedMessage } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify'
import { injectIntl } from 'react-intl'
import { useKeycloak } from '@react-keycloak/web';
import 'react-toastify/dist/ReactToastify.css';
import { useRollbar } from '@rollbar/react';
import { get } from 'lodash';

//constant
import { ENUMS } from 'constant';

// Actions
import * as UserActions from 'redux/actions/UserActions';
import * as KeycloakUserActions from 'redux/actions/KeycloakUserActions';
import * as ErrorActions from 'redux/actions/ErrorActions';
import { getErrorList } from 'redux/selectors/ErrorSelector';

const AdminRoutes = lazy(() => import('./AdminRoutes'));
const UserRoutes = lazy(() => import('./UserRoutes'));

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const SuspenseLoading = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#5383ff'} loading={true} />
        </div>
        {isDev &&
          <div className="text-muted font-size-xl text-center pt-3">
            <FormattedMessage
              id="suspenseloading.text1"
              defaultMessage="Please wait while we load the live preview examples"
            />
            <span className="font-size-lg d-block text-dark">
              <FormattedMessage
                id="suspenseloading.text2"
                defaultMessage="This live preview instance can be slower than a real production build!"
              />
            </span>
          </div>}
      </div>
    </Fragment>
  );
};

const Routes = ({ dispatch, intl, errorList }) => {
  const { keycloak } = useKeycloak();
  const rollbar = useRollbar();
  const [component, setComponent] = React.useState('');

  React.useCallback(() => {
    keycloak?.login()
  }, [keycloak]);

  /**
   * Fetch user info
   */
  React.useEffect(() => {
    async function auth() {
      if (keycloak.authenticated) {
        setComponent(<SuspenseLoading />);
        await dispatch(KeycloakUserActions.addToken(keycloak.token));
        await dispatch(UserActions.getUserInfo()).then(user => {
          if (rollbar.options.enabled) {
            const id = get(user, 'payload.data.id');
            rollbar.configure({
              payload: {
                // The usual
                person: {
                  id
                }
              }
            });
          }

          return user;
        });

        if (keycloak.hasResourceRole(ENUMS.Roles.User, keycloak.clientId)) {
          setComponent(<UserRoutes />);
        } else if (keycloak.hasResourceRole(ENUMS.Roles.Admin, keycloak.clientId)) {
          setComponent(<AdminRoutes />);
        }
      }
    }

    auth();
  }, [dispatch, keycloak, keycloak.authenticated, keycloak.token, rollbar]);

  React.useEffect(() => {
    errorList.forEach((error, index) => {
      if (keycloak.authenticated) {
        toast(error.translateType ? intl.formatMessage({ id: error.message }, error.translateParams) : error.message);
      }
      dispatch(ErrorActions.removeError());
    })
  }, [errorList, dispatch, keycloak.authenticated, intl]);
  return (
    <Suspense fallback={<SuspenseLoading />}>
      {component}
      <ToastContainer />
    </Suspense>
  );
};

const state = createStructuredSelector({
  errorList: getErrorList
});
export default connect(state)(injectIntl(Routes));
