import * as urls from './urls';
import * as columns from './columns';
import * as enums from './enum';
import * as test from './test';

export const TEST = process.env.NODE_ENV === 'development' ? true : false;
// Redux
export const SUCCESS_SUFFIX = '_SUCCESS';
export const ERROR_SUFFIX = '_FAIL';

// API URLS
export const API_URLS = urls;

export const PAGE_TITLE = 'eMoneyFlow Payoutmanager';

// Table Column Datas

export const COLUMNS = columns;

export const ENUMS = enums;

export const TESTDATA = test;

export const statusValues = [
  {
    name: 'status.scheduled',
    default: 'SCHEDULED',
    value: 'scheduled',
    icon: true,
    color: '#CBCBCB'
  },
  {
    name: 'status.success',
    default: 'SUCCESS',
    value: 'success',
    icon: true,
    color: '#5FDD35'
  },
  {
    name: 'status.error',
    default: 'ERROR',
    value: 'error',
    icon: true,
    color: '#EF394F'
  },
  {
    name: 'status.pending',
    default: 'PENDING',
    value: 'pending',
    icon: true,
    color: '#FDC93D'
  },
  {
    name: 'status.declined',
    default: 'DECLINED',
    value: 'declined',
    icon: true,
    color: '#EF394F'
  },
  {
    name: 'status.chargedback',
    default: 'CHARGEDBACK',
    value: 'chargedBack',
    icon: true,
    color: 'yellow'
  }
];
export const stateValues = {
  "created": {
    name: 'status.created',
    default: 'CREATED',
    value: 'created',
    icon: true,
    color: '#00673D'
  },

  "scheduled": {
    name: 'status.scheduled',
    default: 'SCHEDULED',
    value: 'scheduled',
    icon: true,
    color: '#FDFF3D'
  },
  "failed": {
    name: 'status.error',
    default: 'ERROR',
    value: 'error',
    icon: true,
    color: '#EF394F'
  },
  "paid": {
    name: 'status.success',
    default: 'SUCCESS',
    value: 'success',
    icon: true,
    color: '#5FDD35'
  },
  "pendingReview": {
    name: 'status.pending',
    default: 'PENDING',
    value: 'pending',
    icon: true,
    color: '#FDC93D'
  },

  "pendingSubmission": {
    name: 'status.pending',
    default: 'PENDING',
    value: 'pending',
    icon: true,
    color: '#FDC93D'
  },

  "discarded": {
    name: 'status.declined',
    default: 'DECLINED',
    value: 'declined',
    icon: true,
    color: '#8334D6'
  },
  "submitted": {

  },
  "expired": {

  },
  "chargedBack": {

  },

};

export const DATE_FORMAT_TYPES = {
  "mdy": "MM/dd/yyyy",
  "dmy": "dd/MM/yyyy",
  "ymd": "yyyy/MM/dd"
}
export const TIME_FORMAT_TYPES = {
  "24": "24",
  "12": "12"
}
export const NUMBER_FORMAT_TYPES = {
  "1": "1",
  "2": "2"
}
export const AVAILABLE_TEXT_COLORS = ['primary', 'black', 'grey'];

export const AVAILABLE_COLORS = ['cyan', 'primary'];

export const DEFAULT_PAGNATION_COUNT = 10;
