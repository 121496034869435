import Keycloak from 'keycloak-js';
import settings from 'config/settings';

let url =
  settings.authUrl ||
  window.location.origin.replace(/:\d{1,}/, '') + ':8080/auth';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  realm: 'payoutmanager',
  url,
  clientId: 'react-ui'
});

export default keycloak;

