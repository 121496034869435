// Dummy Data : Note: this is just for devs
export const SET_DUMMY_DATA = 'SET_DUMMY_DATA';

// User
export const CHECK_EMAIL_EXISTS = 'CHECK_EMAIL_EXISTS';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOGIN = 'USER_AUTH';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SUBSCRIBE = 'USER_SUBSCRIBE';
export const USER_OTP_CHECK = 'USER_OTP_CODE';
export const USER_SET_USERNAME = 'USER_SET_USERNAME';
export const USER_GET_INFO = 'USER_GET_INFO'
export const USER_UPDATE_INFO = 'USER_UPDATE_INFO'
export const USER_GET_QR_CODE = 'USER_GET_QR_CODE'
export const USER_ENABLE_MFA = 'USER_ENABLE_MFA'
export const USER_DISABLE_MFA = 'USER_DISABLE_MFA'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const USER_ENABLED_MFA = 'USER_ENABLED_MFA'

// Admin Transaction Dashboard
export const ADMIN_GET_TRANSACTION_LIST = 'ADMIN_GET_TRANSACTION_LIST';
export const ADMIN_GET_TRANSACTION = 'ADMIN_GET_TRANSACTION';

// Admin Company Dashboard
export const ADMIN_COMPANY_GET_LIST = 'ADMIN_COMPANY_GET_LIST';
export const ADMIN_COMPANY_GET = 'ADMIN_COMPANY_GET';
export const ADMIN_COMPANY_ADD_EDIT = 'ADMIN_COMPANY_ADD_EDIT';
export const ADMIN_COMPANY_DELETE = 'ADMIN_COMPANY_DELETE';

// Admin User Overview
export const ADMIN_USER_GET_LIST = 'ADMIN_USER_GET_LIST';
export const ADMIN_USER_GET = 'ADMIN_USER_GET';
export const ADMIN_USER_ADD = 'ADMIN_USER_ADD';
export const ADMIN_USER_EDIT = 'ADMIN_USER_EDIT';
export const ADMIN_USER_DELETE = 'ADMIN_USER_DELETE';
export const ADMIN_USER_ACTIVE = 'ADMIN_USER_ACTIVE';
export const ADMIN_USER_RESET = 'ADMIN_USER_RESET';
export const ADMIN_USER_OTP = 'ADMIN_USER_OTP';

// User Dashboard
export const USER_DASHBOARD_GET_KPIS = 'USER_DASHBOARD_GET_KPIS';
export const USER_DASHBOARD_GET_DEBITS = 'USER_DASHBOARD_GET_DEBITS';
export const USER_DASHBOARD_GET_BALANCE = 'USER_DASHBOARD_GET_BALANCE';
export const USER_DASHBOARD_GET_TOP_PAYEES = 'USER_DASHBOARD_GET_TOP_PAYEES';
export const USER_DASHBOARD_GET_FEES = 'USER_DASHBOARD_GET_FEES';

// User Transaction Dashboard
export const USER_GET_TRANSACTION_LIST = 'USER_GET_TRANSACTION_LIST';
export const USER_GET_TRANSACTION = 'USER_GET_TRANSACTION';

// Payee CSV Upload
export const PAYEE_UPLOAD_CSV_SAVE_DATA = 'PAYEE_UPLOAD_CSV_SAVE_DATA';
export const PAYEE_UPLOAD_CSV_FINISH = 'PAYEE_UPLOAD_CSV_FINISH';

// Payout CSV Upload
export const PAYOUT_UPLOAD_CSV_SAVE_DATA = 'PAYOUT_UPLOAD_CSV_SAVE_DATA';
export const PAYOUT_UPLOAD_CSV_PERFORM_UPLOAD = 'PAYOUT_UPLOAD_CSV_PERFORM_UPLOAD';

// Add Payee
export const ADD_PAYEE_SAVE_DATA = 'ADD_PAYEE_SAVE_DATA';

// Payment
export const PAYOUT_SINGLE_SAVE_DATA = 'PAYOUT_SINGLE_ADD_DATA';
export const PAYOUT_SINGLE_REMOVE_DATA = 'PAYOUT_SINGLE_REMOVE_DATA';
export const PAYOUT_SINGLE_LIST = 'PAYOUT_SINGLE_LIST';
export const PAYOUT_EXECUTED_BY_UPLOAD = 'PAYOUT_EXECUTED_BY_UPLOAD';

export const ADD_PAYOUT_SAVE_DATA = 'ADD_PAYOUT_SAVE_DATA';
export const ADD_PAYOUT_REMOVE_DATA = 'ADD_PAYOUT_REMOVE_DATA';
export const ADD_PAYOUT_LIST = 'ADD_PAYOUT_LIST';
export const ADD_PAYOUT_PERFORM_UPLOAD = 'ADD_PAYOUT_PERFORM_UPLOAD';


// Payee
export const PAYEE_LIST = 'PAYEE_LIST';
export const PAYEE_DETAILS = 'PAYEE_DETAILS';
export const PAYEE_UNSET_DETAILS = 'PAYEE_UNSET_DETAILS';
export const PAYEE_SAVE = 'PAYEE_SAVE';
export const PAYEES_CREATE = 'PAYEES_CREATE';
export const PAYEE_DELTE = 'PAYEE_DELETE';


// Payout
export const PAYOUT_SAVE = 'PAYOUT_SAVE';
export const PAYOUT_SAVE_LOCAL = 'PAYOUT_SAVE_LOCAL';
export const PAYOUT_METHODS = 'PAYOUT_METHODS';
export const PAYOUT_CURRENCIES = 'PAYOUT_CURRENCIES';


// ERROR
export const ERROR_ADD = 'ERROR_ADD'
export const ERROR_REMOVE_FIRST = 'ERROR_REMOVE_FIRST'

// KEYCLOAK USER
export const KEYCLOAK_TOKEN_ADD = 'KEYCLOAK_TOKEN_ADD';
