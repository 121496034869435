import { SUCCESS_SUFFIX } from 'constant';
import * as actionTypes from 'redux/actionTypes';
import { USER_PAYEE_DELETE, USER_PAYEE_LIST, USER_PAYEE_SAVE, USER_PAYEE_GET_BY_ID } from 'constant/urls';
import Fuse from 'fuse.js';
import {
  map,
  filter,
  first,
  split,
  slice,
  join
} from 'lodash';
import { findPaymentMethod } from 'utils/payment';

function sanitize(payee, paymentMethods) {
  let { name, firstName, lastName } = payee;
  if (!name) {
    name = name || firstName + ' ' + lastName;
  } else {
    const names = split(name, ' ');
    firstName = first(names);
    lastName = join(slice(names, 1), ' ');
  }

  const paymentMethod = findPaymentMethod(payee.method, paymentMethods);

  return {
    ...payee,
    name,
    firstName,
    lastName,
    method: paymentMethod?.method,
  }
}

export const fetchPayeeList = (keyword, countPerPage, pageNumber) => (dispatch) => {
  return dispatch({
    type: actionTypes.PAYEE_LIST,
    payload: {
      request: {
        url: `${USER_PAYEE_LIST}?countPerPage=${countPerPage || ''}&pageIndex=${pageNumber || ''}&keyword=${keyword || ''}`,
        method: 'GET',
      }
    }
  });
};

export const fetchPayeeDetails = (id) => (dispatch) => {
  return dispatch({
    type: actionTypes.PAYEE_DETAILS,
    payload: {
      request: {
        url: USER_PAYEE_GET_BY_ID + id,
        method: 'GET',
      }
    }
  });
};

export const unsetPayeeDetails = () => (dispatch) => {
  dispatch({
    type: actionTypes.PAYEE_UNSET_DETAILS,
    payload: {
      data: {
        details: null
      }
    }
  });
};

export const searchPayeeList = (search) => (dispatch, getState) => {
  let list = getState().payee?.all;

  if (search) {
    const options = {
      includeScore: false,
      // Search in `author` and in `tags` array
      keys: ['name', 'firstName', 'lastName', 'iban', 'affiliateId']
    };
    const fuse = new Fuse(getState().payee?.all || [], options);
    const result = fuse.search(search);
    list = map(result, (r) => r.item);
  }

  dispatch({
    type: actionTypes.PAYEE_LIST + SUCCESS_SUFFIX,
    payload: {
      data: {
        search,
        list
      }
    }
  });
};

export const addPayees = (payees, paymentMethods) => (dispatch, getState) => {
  return dispatch({
    type: actionTypes.PAYEES_CREATE,
    payload: {
      request: {
        url: USER_PAYEE_SAVE,
        method: 'POST',
        data: map(filter(payees, p => p.isValid !== false), p => sanitize(p, paymentMethods))
      }
    }
  });
};

export const addPayee = (payees, paymentMethods) => (dispatch, getState) => {
  return dispatch({
    type: actionTypes.PAYEES_CREATE,
    payload: {
      request: {
        url: USER_PAYEE_SAVE + '?single=true',
        method: 'POST',
        data: map(filter(payees, p => p.isValid !== false), p => sanitize(p, paymentMethods))
      }
    }
  });
};

export const savePayee = (payee) => (dispatch) => {
  return dispatch({
    type: actionTypes.PAYEE_SAVE,
    payload: {
      request: {
        url: USER_PAYEE_SAVE,
        method: 'PUT',
        data: payee
      }
    }
  });
};

export const deletePayee = (payee) => (dispatch, getState) => {

  return dispatch({
    type: actionTypes.PAYEE_DELTE,
    payload: {
      request: {
        url: USER_PAYEE_DELETE,
        method: 'DELETE',
        data: {
          id: payee.id
        }
      }
    }
  });
};

export const PayeeActions = [
  actionTypes.PAYEE_LIST + SUCCESS_SUFFIX,
  actionTypes.PAYEE_UNSET_DETAILS,
  actionTypes.PAYEE_DETAILS + SUCCESS_SUFFIX
];
