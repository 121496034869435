export const GET_TRANSACTION = 'api/get/transaction';
export const GET_TRANSACTION_LIST = 'api/get/transaction/list'

export const ADMIN_COMPANY = '/admin/company'
export const ADMIN_USER = '/admin/user'
export const ADMIN_USER_MFA = '/admin/user/mfa'
export const ADMIN_USER_RESET = '/admin/user/resetpwd'
export const ADMIN_GET_TRANSACTION_LIST = '/admin/transaction'
export const ADMIN_PAYOUT_METHODS = '/admin/payment/methods'

export const USER_GET_TRANSACTION_LIST = '/user/transaction'

export const USER_PAYEE_LIST = 'user/payee'
export const USER_PAYEE_SAVE = 'user/payee'
export const USER_PAYEE_DELETE = 'user/payee'
export const USER_PAYEE_GET_BY_ID = 'user/payee/'

export const USER_PAYOUT = 'user/payout';
export const USER_PAYOUT_METHODS = '/user/payment/methods';
export const USER_PAYOUT_CURRENCIES = '/user/payment/currencies';

export const USER_GET_INFO = 'user'
export const USER_UPDATE_INFO = 'user/update'
export const USER_MFA = 'mfa'

export const USER_DASHBOARD_GET_KPIS = 'user/dashboard/kips';
export const USER_DASHBOARD_GET_DEBITS = 'user/dashboard/debits';
export const USER_DASHBOARD_GET_BALANCE = 'user/dashboard/balance';
export const USER_DASHBOARD_GET_TOP_PAYEES = 'user/dashboard/payees';
export const USER_DASHBOARD_GET_FEES = 'user/dashboard/fees';