import React from 'react';
import ReactDOM from 'react-dom';
import { version } from '../package.json';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, loadStateFromStorage } from './config/store/configureStore';
window.appVersion = version;

loadStateFromStorage(store).then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
