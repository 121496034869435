import * as actionTypes from 'redux/actionTypes';

const KeycloakUserReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.KEYCLOAK_TOKEN_ADD: {
      return {
        ...state,
        token: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default KeycloakUserReducer;